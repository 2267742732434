import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        
        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Development and support for ALL Mosso.Pro management information systems.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Automate your business processes with Mosso.Pro.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Progressive Web Apps (PWAs) for efficient management from remote locations.</p>
            </li>
          </ul>
        </article>
        
        <article className="service">
          <div className="service__head">
            <h3>Data Analysis</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Dynamic and interactive reporting dashboards for improved business decision making.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Statistical methods to create data models and stunning visualisations.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Automated reporting infrastructures for frequent updates.</p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Content Management</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Creation and enforcement of contextual digital marketing strategies for SMEs.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Content creation for blogs and social media platforms.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Detailed trend reports and content engagement summaries.</p>
            </li>
          </ul>
        </article>
      </div>

    </section>
  )
}

export default Services