import React from 'react'
import {BsLinkedin, BsInstagram} from 'react-icons/bs'

const HeaderSocials = () => {
  return (
      <div className="header__socials">
          <a href="https://www.linkedin.com/in/kevin-mruma/" target="_blank" ><BsLinkedin /></a>
          <a href="https://www.instagram.com/mosso.pro/" target="_blank" ><BsInstagram /></a>
      </div>
  )
}

export default HeaderSocials