import React from 'react'
import {useRef, useState} from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'
import {BsLinkedin, BsInstagram} from 'react-icons/bs'
import emailjs from 'emailjs-com'
import Alert from '@mui/material/Alert';

const Contact = () => {
  const form = useRef()

  const sendEmail = e => {
    e.preventDefault()

    emailjs.sendForm('service_mvl47le', 'template_it2pp0l', form.current, 'ZowxuAIcpcUr7SDay')
    setSent(true)
  }

  const [sent, setSent] = useState(false)
  

  return (
    <section id="contact">

      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>kevin.mruma@mosso.pro</h5>
            <a href="mailto:kevin.mruma@mosso.pro">Send a Message</a>
          </article>
          
          <article className="contact__option">
            <BsLinkedin className="contact__option-icon" />
            <h4>LinkedIn</h4>
            <h5>Mosso Systems</h5>
            <a href="https://www.linkedin.com/company/mosso-systems/">Send a Message</a>
          </article>

          <article className="contact__option">
            <BsWhatsapp className="contact__option-icon" />
            <h4>WhatsApp</h4>
            <h5>+255 674 950 500</h5>
            <a href="https://wa.me/255674950500">Send a Message</a>
          </article>
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="Name" placeholder="Your Name" required />
          <input type="email" name="Email" placeholder="Your Email" required />
          <textarea name="Message" rows="7" placeholder="Your Message" required ></textarea>
          <button type="submit" className="btn btn-primary">Send Message</button>
        </form>

        {sent && sent == true ? 
          <Alert severity="success">YOUR MESSAGE HAS BEEN SENT, WE'LL GET BACK TO YOU SOON.</Alert>
        : ''}

      </div>
    </section>
  )
}

export default Contact