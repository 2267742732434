import React from 'react'
import './footer.css'
import {BsLinkedin, BsInstagram} from 'react-icons/bs'

const Footer = () => {
  return (
    <footer>
      <a href="#" className="footer__logo">Mosso Systems</a>
      
      <ul className="permalinks">
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/kevin-mruma/" target="_blank" ><BsLinkedin /></a>
        <a href="https://www.instagram.com/mosso.pro/" target="_blank" ><BsInstagram /></a>
      </div>
      <div className="footer__copyright">
        <small>&copy; Mosso Systems. All Rights Reserved.</small>
      </div>

    </footer>
  )
}

export default Footer