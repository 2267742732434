import React from 'react'
import './portfolio.css'
import hrmis from '../../assets/hrmis.jpg'
import fleet from '../../assets/fleet.jpg'
import tickets from '../../assets/tickets.jpg'

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={hrmis} alt="HR System" />
          </div>
          <h3>HR Management System</h3>
          <div className="portfolio__item-cta">
            <a href="https://instagram.com/" className="btn" target="_blank" rel="noreferrer">Learn More</a>
            <a href="https://hr.mosso.pro/" className="btn btn-primary" target="_blank" rel="noreferrer">Live Demo</a>
          </div>
        </article>
        
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={fleet} alt="Fleet System" />
          </div>
          <h3>Fleet Management System</h3>
          <div className="portfolio__item-cta">
            <a href="https://instagram.com/" className="btn" target="_blank" rel="noreferrer">Learn More</a>
            <a href="https://hr.mosso.pro/" className="btn btn-primary" target="_blank" rel="noreferrer">Live Demo</a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={tickets} alt="Ticket Booking System" />
          </div>
          <h3>Bus Ticket Booking System</h3>
          <div className="portfolio__item-cta">
            <a href="https://instagram.com/" className="btn" target="_blank" rel="noreferrer">Learn More</a>
            <a href="https://safariticket.co.tz" className="btn btn-primary" target="_blank" rel="noreferrer">Book Now</a>
          </div>
        </article>

      </div>

    </section>
  )
}

export default Portfolio