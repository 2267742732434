import React from 'react'
import './header.css'
import CTA from './CTA'
import nani from '../../assets/me.png'
import HeaderSocials from './HeaderSocials'


const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hello, I'm </h5>
        <h1>Kevin Mruma</h1>
        <h5 className="light-text">Fullstack Developer & Data Analyst</h5>
        {/* <h1>Kevin Mruma<span className="degrees">, BSc, MBA</span></h1> */}
        
        <CTA />
        <HeaderSocials />

        <div className="nani">
          <img src={nani} alt="Mosso.Pro" />
        </div>

        <a href="#contact" className="scroll__down">Scroll Down</a>

      </div>
    </header>
  )
}

export default Header